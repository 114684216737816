@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0a0d14;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  background-color: #f1f3f4;
  border-radius: 20px;
  height: 100%;
  position: relative;
  z-index: 2;
}

.form-wrapper {
  display: grid;
  background-color: #101419;
  padding: 15px;
  gap: 20px;
  border-radius: 30px;
  grid-template-columns: 300px minmax(0, 1fr);
  overflow: hidden;
  position: relative;
}

@media (max-width: 768px) {
  .form-wrapper {
    grid-template-columns: 1fr;
  }
  .form-left {

  }
  .image-heymann {
    width: 279px;
    position: absolute;
    top: -50px;
    transform: translateX(20px) translateY(50px);
    right: -40px;
    z-index: 1;
  }
  .contact-data {
    margin-top: 150px;
    position: relative;
    z-index: 2;    
  }
}

.image-heymann {
  width: 279px;
  transform: translateX(20px) translateY(50px);
}

.contact-data * {
  color: #f1f3f4;
}

.contact-details {
  background-color: #131417;
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
  position: relative;
  z-index: 2;
}

#name, #title {
  font: 600 14px/160% sans-serif;
  color: #f1f3f4;
}

#privacy {
  color: #4440ff;
}

#position, #email a, #phone a, #address a {
  font: 400 14px/140% sans-serif;
  color: #75787b;
}

#email a:hover,
#phone a:hover,
#address a:hover {
  color: #4440ff;
}

button {
  background-color: #4440ff;
}